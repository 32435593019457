<script>
import DepartmentService from "@/services/DepartmentService";
import PriceRoleService from "@/services/PriceRoleService";
import VUserForm from "@/components/Forms/VUserForm";
import VFilters from "@/components/Search/VFilters";
import UserService from "@/services/UserService";
import LoginService from "@/services/LoginService";
import AppCfg from "@/mixins/AppCfg";
import Messages from "@/mixins/Messages";
import { mapGetters } from "vuex";

export default {
    name: "VIndex",
    components: {
        VFilters,
        VUserForm
    },
    mixins: [AppCfg, Messages],
    data() {
        return {
            users: [],
            prices: [],
            loading: false,
            meta: {},
            editing: false,
            departments: [],
            editContext: { empty: true },
            filterViews: {
                active: this.$t("users.filter_active"),
                inactive: this.$t("users.filter_inactive"),
                all: this.$t("users.filter_all")
            }
        };
    },
    computed: {
        ...mapGetters("auth", ["getAclRole"])
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.USER_MGMT);
        this.apiPaginator.sort = "name";
        this.apiPaginator.direction = "asc";
        this.apiFilterView = "all";
        this.apiSearchFilter = { name: "" };
        await this.fetchDepartments();
        await this.fetchPrices();
        this.$apiEnableWatcher();
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await UserService.GET(params);
                this.users = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
        /**
         * Fetch departments
         */
        async fetchDepartments(loading = true) {
            try {
                this.loading = true;
                const r = await DepartmentService.dropdown();

                this.departments = r.data.data;
                console.log(this.departments, "deps");
            } catch (e) {
                console.log(e);
            }

            this.loading = loading;
        },
        async fetchPrices(loading = true) {
            try {
                this.loading = true;
                const r = await PriceRoleService.GET_dropdown();
                this.prices = r.data.data;
            } catch (e) {
                console.log("fetchPrices", e);
            }
        },
        initSearch() {
            return {
                name: "",
                email: "",
                position: "",
                department_id: null
            };
        },
        /**
         * Reset search
         */
        resetSearch() {
            this.apiSearchFilter = this.initSearch();
        },
        /**
         * Initiate password reset
         */
        async resetPassword(email) {
            try {
                const r = await LoginService.inviteUser(email);
                this.$msgSuccess(this.$t("user.invite_email_sent"));
            } catch (e) {
                this.$swal({
                    icon: "error",
                    title: this.$t("user.cannot_invite_user"),
                    text: this.$t("unknown_error")
                });
            }
        },
        async handleResetPassword(email) {
            this.$swal({
                icon: "warning",
                text: this.$t("users.swal_confirm_user_password_reset"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await this.resetPassword(email);
                }
            });
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <a
                    v-if="ACL_RW"
                    class="btn btn-sm btn-secondary"
                    href="/v1/users/export-csv"
                    >{{ $t("users.export_all_to_csv") }}</a
                >
                <base-button
                    v-if="ACL_RW"
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("users.create_new") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        :filter-views="filterViews"
                        :filter-view="apiFilterView"
                        :filter-name="$t('users.filter_access')"
                        filter-icon="far fa-key"
                        @filter-view-change="$handleFilterView($event)"
                        @reset-search="resetSearch"
                        :search="true"
                        v-model="apiSearchFilter.name"
                        :search-placeholder="$t('users.search')"
                    >
                        <template slot="default">
                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.department_id"
                                    :placeholder="
                                        $t('users.placeholder_department')
                                    "
                                    type="select"
                                    :options="departments"
                                />
                            </div>

                            <div class="col">
                                <FormulateInput
                                    v-model="apiSearchFilter.acl_role"
                                    :placeholder="
                                        $t('users.placeholder_acl_role')
                                    "
                                    :options="
                                        appCfgRead('Users.acl_role', true)
                                    "
                                    type="select"
                                />
                            </div>
                            <div class="col">
                                <FormulateInput
                                    type="select"
                                    v-model="apiSearchFilter.price_role_id"
                                    :placeholder="
                                        $t('users.placeholder_price_role')
                                    "
                                    :options="prices"
                                />
                            </div>
                        </template>
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="users.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th class="text-center"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="employee_number"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.empl_no")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.name")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="email"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.email")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="position"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.position")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Departments.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.department")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                v-if="ACL_RW"
                                                class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="daily_time_goal"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "users.daily_time_goal"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th v-if="ACL_RW"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="acl_role"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.role")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th v-if="ACL_RW"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="default_hour_price"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "users.default_hour_price"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="default_hour_price"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("users.access")
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th
                                                v-if="ACL_RW"
                                                class="text-right"
                                            ></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="u in users"
                                            :key="u.id"
                                            :class="{
                                                'bg-inactive': !u.active
                                            }"
                                        >
                                            <v-td class="text-center">
                                                <template
                                                    v-if="u.employee_number"
                                                    >{{
                                                        u.employee_number
                                                    }}</template
                                                >
                                                <template v-else>---</template>
                                            </v-td>
                                            <v-td>
                                                {{ u.name }}
                                            </v-td>
                                            <v-td>
                                                {{ u.email }}
                                            </v-td>
                                            <v-td>
                                                <template v-if="u.position">{{
                                                    u.position
                                                }}</template>
                                                <span v-else>---</span>
                                            </v-td>
                                            <v-td>
                                                <template v-if="u.department">{{
                                                    u.department.name
                                                }}</template>
                                                <span v-else>---</span>
                                            </v-td>
                                            <v-td
                                                v-if="ACL_RW"
                                                class="text-right"
                                            >
                                                <template
                                                    v-if="
                                                        parseInt(
                                                            u.daily_time_goal
                                                        ) === 0
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            "users.paid_per_hour"
                                                        )
                                                    }}
                                                </template>
                                                <template v-else>
                                                    {{
                                                        u.daily_time_goal
                                                            | toTime
                                                    }}
                                                </template>
                                            </v-td>
                                            <v-td v-if="ACL_RW">
                                                {{ u.acl_role_name }}
                                            </v-td>

                                            <v-td v-if="ACL_RW">
                                                {{ u.price_role.role_name }}
                                            </v-td>

                                            <v-td class="text-center">
                                                <i
                                                    class="far"
                                                    :class="{
                                                        'fa-check': u.active,
                                                        'fa-ban': !u.active
                                                    }"
                                                ></i>
                                            </v-td>
                                            <v-td
                                                v-if="ACL_RW"
                                                class="text-right"
                                            >
                                                <base-dropdown
                                                    :menu-on-right="true"
                                                    :has-toggle="false"
                                                >
                                                    <template
                                                        slot="title-container"
                                                        ><i
                                                            class="far fa-ellipsis-h fa-lg"
                                                        ></i
                                                    ></template>
                                                    <div
                                                        class="dropdown-item"
                                                        @click="setContext(u)"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-pencil"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "users.action_edit"
                                                            )
                                                        }}
                                                    </div>
                                                    <a
                                                        class="dropdown-item"
                                                        :href="
                                                            `/v1/users/impersonate/${u.id}`
                                                        "
                                                        v-if="
                                                            getAclRole ===
                                                                'ROOT'
                                                        "
                                                    >
                                                        <i
                                                            class="far fa-fw fa-sign-in"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "users.action_impersonate"
                                                            )
                                                        }}
                                                    </a>
                                                    <div
                                                        v-if="
                                                            ACL_RW && u.active
                                                        "
                                                        @click="
                                                            handleResetPassword(
                                                                u.email
                                                            )
                                                        "
                                                        class="dropdown-item text-danger"
                                                    >
                                                        <i
                                                            class="far fa-fw fa-key-skeleton"
                                                        ></i>
                                                        {{
                                                            $t(
                                                                "users.action_password_reset"
                                                            )
                                                        }}
                                                    </div>
                                                </base-dropdown>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-user-form
                v-if="editing"
                :context="editContext"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.bg-inactive {
    background-color: $gray-200;
}

@media screen and (min-width: 1300px) {
    .table-responsive {
        overflow-x: auto !important;
    }
}
</style>
