import Http from "@/libraries/Http";


export default {
    apiVersion: 'v1',

    async refreshMyAuthData() {
        return Http.get(`/${this.apiVersion}/users/refresh-my-auth-data`);
    },

    // Login moved to auth vuex module

    async logout() {
        return Http.post(`/${this.apiVersion}/logout`);
    }, 

    /**
     * Create a password reset request
     * @param {} email 
     */
    async passwordReset(email) {
        return Http.post(`/${this.apiVersion}/users/password-reset`, {email: email});
    },

    /**
     * Verify password token
     * @param {} token 
     */
    async verifyToken(token) {
        return Http.post(`/${this.apiVersion}/users/verify-token`, {token: token});
    },

    /**
     * Re-invite the user
     * @param {} email 
     */
    async inviteUser(email) {
        return Http.post(`/${this.apiVersion}/users/invite-user`, {email: email});
    },

    /** 
     * Setup a new password.
     * `data` contains:
     * - new password : password
     * - a valid token : token
     */
    async password(data) {
        return Http.put(`/${this.apiVersion}/users/password`, data);
    }

}